import { WebSdkModel } from 'packages/embedded-web-sdk';

export interface BackButtonHandlerPluginCreator {
  (exec: WebSdkModel['exec']): Promise<BackButtonHandlerPlugin>;
}

export type BackButtonEventListener = {
  (event: Event): void;
};

export interface BackButtonHandlerPlugin {
  /**
   * Add an event listener for back button handler.
   * Call event.preventDefault() in a handler to prevent the standard back
   * button behavior from occurring
   */
  addEventListener(listener: BackButtonEventListener): void;

  /**
   * Remove an event listener for cleanup
   */
  removeEventListener(listener: BackButtonEventListener): void;
}

const noop = () => {
  // noop
};

export const createBackButtonHandlerPlugin: BackButtonHandlerPluginCreator = async (
  exec
) => {
  const eventName = 'netspend.onBackButton';
  const pluginApi = await exec({
    plugin: 'pluginManager',
    action: 'getApi',
    params: null
  });
  const {
    netspendSdk: { onBackButton = false, triggerBackButton = false } = {}
  } = pluginApi || {};

  if (!onBackButton) {
    // Return a stub impl if there is no back button plugin, like on iOS
    return { addEventListener: noop, removeEventListener: noop };
  }

  const onBackButtonPressed = () => {
    const event = new CustomEvent(eventName, { cancelable: true });
    const cancelled = !window.dispatchEvent(event);
    if (cancelled || !triggerBackButton) {
      return;
    }

    // Now we trigger the back button for real, after handlers have had a chance
    // don't care about the result, we just hope it works
    exec({
      plugin: 'netspendSdk',
      action: 'triggerBackButton',
      params: null
    });
  };

  exec(
    {
      plugin: 'netspendSdk',
      action: 'onBackButton',
      params: null
    },
    onBackButtonPressed,
    noop
  );

  // Expose the API where we attach real listeners.
  // By leveraging emitting events on `window` we are able to
  // not maintain our own listener queue, error handling, default prevention
  // AND, if multiple instances of this plugin are used, they should not collide
  // because since on the native side there is only ever one back button handler
  return {
    addEventListener(listener) {
      window.addEventListener(eventName, listener);
    },
    removeEventListener(listener) {
      window.removeEventListener(eventName, listener);
    }
  };
};
